import React from 'react'
import PropTypes from 'prop-types'

import { Col, Container, Row } from 'reactstrap'

import Image from 'components/ui/image'
import RenderBlock from 'components/ui/block-text'
import ItemBenefits from './item/ItemBenefits'

const Benefits = ({ title, background, items }) => {
  return (
    <section className="benefits">
      <Image data={background} className="benefits__bg-image" />
      <Container fluid className="benefits__main-container py-lg-5">
        <Row>
          <Col xs={12} lg={12} className="">
            <RenderBlock
              className="benefits__title mt-5 mb-4 d-lg-flex justify-content-lg-end"
              data={title}
            />
          </Col>
        </Row>
        <Row className="justify-content-end">
          {items?.map(item => (
            <Col xs={12} lg={7} key={item?._key} className="">
              <ItemBenefits
                key={item?._key}
                image={item?.icon}
                description={item?._rawDescription}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

Benefits.propTypes = {
  title: PropTypes.shape({}).isRequired,
  background: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default Benefits
