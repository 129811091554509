import React from 'react'
import PropTypes from 'prop-types'

import { Container, Row, Col } from 'reactstrap'

import Image from 'components/ui/image'
import RenderBlock from 'components/ui/block-text'
import Anchor from 'components/ui/anchor-link'

import Countdown from 'react-countdown'

import ArrowRightIcon from 'components/icons/ArrowRightIcon'

const Penalties = ({ title, subtitle, description, cta, background }) => {
  /* start countdown component */
  const Completionist = () => <span>You are good to go!</span>

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />
    }
    return (
      <Row className="count-down justify-content-center align-items-center">
        <Col
          xs={1}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <p className="count-down__text mb-1">DÍAS</p>
          <div className="count-down__time-container">
            <span className="count-down__time">{days}</span>
          </div>
        </Col>
        <span className="count-down__periods mx-4 mx-lg-2">:</span>
        <Col
          xs={1}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <p className="count-down__text mb-1">HORAS</p>
          <div className="count-down__time-container">
            <span className="count-down__time">{hours}</span>
          </div>
        </Col>
        <span className="count-down__periods mx-4 mx-lg-2">:</span>
        <Col
          xs={1}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <p className="count-down__text mb-1">MINUTOS</p>
          <div className="count-down__time-container">
            <span className="count-down__time">{minutes}</span>
          </div>
        </Col>
        <span className="count-down__periods mx-4 mx-lg-2">:</span>
        <Col
          xs={1}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <p className="count-down__text mb-1">SEGUNDOS</p>
          <div className="count-down__time-container">
            <span className="count-down__time">{seconds}</span>
          </div>
        </Col>
      </Row>
    )
    /* end countdown component */
  }

  return (
    <section className="penalties py-5">
      <Image data={background} className="penalties__bg-image" />
      <Container fluid className="penalties__main-container">
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={12} lg={8} className="text-center">
            <RenderBlock className="penalties__title mt-3" data={title} />
            <RenderBlock className="penalties__subtitle mt-3" data={subtitle} />
            <Countdown date="2021-08-31T23:59:59" renderer={renderer} />
            <hr className="penalties__separator mt-4 mb-4" />
            <RenderBlock
              className="penalties__description mt-0 px-3"
              data={description}
            />
          </Col>
        </Row>
        <Row className="btn-container mt-2">
          <Col className="d-flex justify-content-center align-items-center">
            <Anchor
              className="btn-secondary btn btn-lg btn-block mt-2"
              text={
                <>
                  <span>{cta?.text}</span>
                  <ArrowRightIcon cssClass="arrow-icon ml-2 mb-1" />
                </>
              }
              link={cta?.link}
              type={cta?.type}
              target={cta?.target}
              enable={cta?.enable}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

Penalties.propTypes = {
  title: PropTypes.shape({}).isRequired,
  subtitle: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  background: PropTypes.shape({}).isRequired,
  cta: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    target: PropTypes.string,
    enable: PropTypes.bool,
  }).isRequired,
}

export default Penalties
