import React from 'react'
import PropTypes from 'prop-types'

import { Col, Container, Row } from 'reactstrap'

import RenderBlock from 'components/ui/block-text'
import ItemHowItWork from './item/ItemHowItWork'

const HowItWork = ({ title, subtitle, description, items, id }) => {
  return (
    <section id={id} className="how-it-work">
      <Container className="how-it-work__main-container">
        <Row className="mt-5 how-it-work__main-content">
          <Col xs={12} lg={12} className="mt-lg-4">
            <RenderBlock className="how-it-work__title" data={title} />
            <RenderBlock className="how-it-work__subtitle" data={subtitle} />
            <hr className="how-it-work__separator mx-0 my-4" />
            <RenderBlock
              className="how-it-work__description pr-lg-5"
              data={description}
            />
          </Col>
        </Row>
        <Row className="how-it-work__article-container d-lg-flex justify-content-center align-items-center">
          {items?.map(item => (
            <Col xs={12} lg={3} key={item?._key}>
              <ItemHowItWork
                key={item?._key}
                title={item?._rawTitle}
                description={item?._rawDescription}
                image={item?.image}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

HowItWork.propTypes = {
  title: PropTypes.shape({}).isRequired,
  subtitle: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
}

export default HowItWork
