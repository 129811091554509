import React from 'react'
import PropTypes from 'prop-types'

import { Col, Container, Row } from 'reactstrap'

import Image from 'components/ui/image'
import RenderBlock from 'components/ui/block-text'

const ItemSlider = ({ title, subtitle, description, icon }) => {
  return (
    <article className="our-services-item">
      <Container className="our-services-item__container">
        <Row className="">
          <Col
            xs={12}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Image data={icon} className="our-services-item__icon mt-3" />
            <RenderBlock
              className="our-services-item__title mb-0"
              data={title}
            />
            <hr className="our-services-item__separator-main mb-4" />
          </Col>
        </Row>
        <Row>
          <Col>
            <RenderBlock
              className="our-services-item__subtitle text-left"
              data={subtitle}
            />
            <hr className="our-services-item__separator my-4" />
            <RenderBlock
              className="our-services-item__description text-left"
              data={description}
            />
          </Col>
        </Row>
      </Container>
    </article>
  )
}

ItemSlider.propTypes = {
  title: PropTypes.shape({}).isRequired,
  subtitle: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  icon: PropTypes.shape({}).isRequired,
}

export default ItemSlider
