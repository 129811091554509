import React from 'react'
import PropTypes from 'prop-types'

import { Container, Row, Col } from 'reactstrap'

import Image from 'components/ui/image'
import RenderBlock from 'components/ui/block-text'
import Anchor from 'components/ui/anchor-link'

const Hero = ({
  banner,
  title,
  description,
  background,
  ctaDataMain,
  ctaDataSecondary,
}) => {
  return (
    <section className="hero">
      <Image data={background} className="hero__bg-image" />
      <Row className="hero__banner-container m-0 pt-2 pb-0 pt-lg-2 pb-lg-0">
        <Col xs={12} className="m-0 p-0">
          <RenderBlock
            className="banner text-center mt-2 mb-lg-2 pb-lg-2"
            data={banner}
          />
        </Col>
      </Row>
      <Container className="hero__main-container h-100 py-5">
        <Row className="h-100 align-items-center">
          <Col xs={12} lg={4} className="">
            <RenderBlock className="title mt-lg-4" data={title} />
            <RenderBlock className="description mt-3" data={description} />
            <Anchor
              className="btn-main btn btn-lg btn-block mt-4 mt-lg-2"
              text={ctaDataMain?.text}
              link={ctaDataMain?.link}
              type={ctaDataMain?.type}
              target={ctaDataMain?.target}
              enable={ctaDataMain?.enable}
            />
            <Anchor
              className="btn-secondary btn btn-lg btn-block mt-3 mb-5 mt-lg-3"
              text={ctaDataSecondary?.text}
              link={ctaDataSecondary?.link}
              type={ctaDataSecondary?.type}
              target={ctaDataSecondary?.target}
              enable={ctaDataSecondary?.enable}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

Hero.propTypes = {
  banner: PropTypes.shape({}).isRequired,
  title: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  background: PropTypes.shape({}).isRequired,
  ctaDataMain: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    target: PropTypes.string,
    enable: PropTypes.bool,
  }).isRequired,
  ctaDataSecondary: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    target: PropTypes.string,
    enable: PropTypes.bool,
  }).isRequired,
}

export default Hero
