import React from 'react'
import PropTypes from 'prop-types'

const PlusIcon = ({ cssClass }) => {
  return (
    <>
      <svg
        className={cssClass}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </svg>
    </>
  )
}

PlusIcon.propTypes = {
  cssClass: PropTypes.string.isRequired,
}

export default PlusIcon
