import React, { useRef, useCallback } from 'react'

import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import querystring from 'querystring'

import { Col, Row } from 'reactstrap'

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const formInstance = useRef()
  const handleSubmit = useCallback(
    async event => {
      event.preventDefault()
      if (!executeRecaptcha) {
        return
      }
      const action = 'contact_us'
      const result = await executeRecaptcha(action)
      if (result) {
        const formData = new FormData(formInstance.current)
        formData.append('to', 'webcontact@sagrilaft.com')
        // formData.append('cc', '')
        formData.append('redirect', 'https://www.sagrilaft.com/')
        formData.append('site', 'https://www.sagrilaft.com/')
        formData.append('token', result)
        formData.append('key', process.env.GATSBY_RECAPTCHA_SECRET_KEY)
        formData.append('action', action)
        let object = {}
        formData.forEach((value, key) => {
          object = { ...object, [key]: value }
        })
        const query = querystring.stringify(object)
        fetch(
          'https://l6kg4l50gi.execute-api.us-east-1.amazonaws.com/send-forms',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: query,
          }
        )
      }
    },
    [executeRecaptcha]
  )

  return (
    <form onSubmit={handleSubmit} ref={formInstance} className="form">
      <input type="hidden" name="to" value="" />
      <input type="hidden" name="redirect" value="" />
      <input type="hidden" name="site" value="" />
      <Row>
        <Col xs={12} lg={6}>
          <div className="form__group field">
            <input
              type="text"
              className="form__field w-100"
              placeholder="Nombre"
              name="name"
              id="name"
              required
            />
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="form__group field">
            <input
              type="text"
              className="form__field w-100"
              placeholder="Apellido"
              name="lastname"
              id="lastname"
              required
            />
          </div>
        </Col>

        <Col xs={12} lg={6}>
          <div className="form__group field">
            <input
              type="number"
              className="form__field w-100"
              placeholder="Teléfono"
              name="phonenumber"
              id="phonenumber"
              required
            />
          </div>
        </Col>

        <Col xs={12} lg={6}>
          <div className="form__group field">
            <input
              type="email"
              className="form__field w-100"
              placeholder="E-mail"
              name="email"
              id="email"
              required
            />
          </div>
        </Col>

        <Col xs={12}>
          <div className="form__group field">
            <textarea
              className="form__field w-100 text-area"
              placeholder="Mensaje"
              name="message"
              id="message"
              required
            />
          </div>
        </Col>
        <Col className="d-flex justify-content-center">
          <button
            type="submit"
            className="form__btn-form btn btn-lg btn-block mt-4 mb-0"
          >
            Enviar
          </button>
          <GoogleReCaptcha />
        </Col>
      </Row>
    </form>
  )
}

export default ContactForm
