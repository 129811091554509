import React from 'react'
import PropTypes from 'prop-types'

import { Container, Row, Col } from 'reactstrap'

import RenderBlock from 'components/ui/block-text'
import ContactForm from './form'

const Contact = ({ title, description, link, id }) => {
  return (
    <section id={id} className="contact">
      <Container className="contact__main-container">
        <Row>
          <Col xs={12} lg={12} className="mt-2 mt-lg-4">
            <RenderBlock
              className="contact__title text-left text-lg-center"
              data={title}
            />
            <RenderBlock
              className="contact__description text-left text-lg-center mt-4"
              data={description}
            />
            <RenderBlock
              className="contact__link text-left text-lg-center mt-3"
              data={link}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

Contact.propTypes = {
  title: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  link: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
}

export default Contact
