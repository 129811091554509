import React from 'react'
import PropTypes from 'prop-types'

import { Col, Container, Row } from 'reactstrap'

import Image from 'components/ui/image'
import RenderBlock from 'components/ui/block-text'
import Anchor from 'components/ui/anchor-link'

const Calculate = ({ title, subtitle, description, cta, background, id }) => {
  return (
    <section id={id} className="calculate">
      <Image data={background} className="calculate__bg-image" />
      <Container className="calculate__main-container text-center pt-lg-3">
        <Row>
          <Col xs={12} lg={12}>
            <RenderBlock className="calculate__title mt-5 px-4" data={title} />
            <RenderBlock className="calculate__subtitle mt-4" data={subtitle} />
            <RenderBlock
              className="calculate__description mt-4 mb-5"
              data={description}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center align-items-center mb-lg-3">
            <Anchor
              className="calculate__btn-main btn btn-lg btn-block mt-2 mb-5 mb-lg-5"
              text={cta?.text}
              link={cta?.link}
              type={cta?.type}
              target={cta?.target}
              enable={cta?.enable}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

Calculate.propTypes = {
  title: PropTypes.shape({}).isRequired,
  subtitle: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  background: PropTypes.shape({}).isRequired,
  cta: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    target: PropTypes.string,
    enable: PropTypes.bool,
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default Calculate
