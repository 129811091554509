/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Col, Container, Row } from 'reactstrap'

import { useMediaQueries } from '@react-hook/media-query'

import RenderBlock from 'components/ui/block-text'
import Image from 'components/ui/image'

import Slider from 'react-slick'

const OurTeam = ({ title, items, id }) => {
  const [mainSlider, setMainSlider] = useState(null)
  const [thumbnails, setThumbnails] = useState(null)
  const mainSliderRef = useRef()
  const thumbnailsRef = useRef()

  const {
    matches: { mobileSlide, tabletSlide },
  } = useMediaQueries({
    mobileSlide: 'only screen and (max-width: 580px)',
    tabletSlide: 'only screen and (max-width: 850px) and (min-width: 581px) ',
  })

  const slidesQuantity = (mobileSlide && 1) || (tabletSlide && 1) || 2

  const settingsDesktop = {
    dots: mobileSlide,
    infinite: false,
    slidesToShow: slidesQuantity,
    slidesToScroll: slidesQuantity,
    arrows: false,
    asNavFor: thumbnails,
    centerPadding: '2px',
    ref: slider => (mainSliderRef.current = slider),
  }

  const settingsThumb = {
    dots: false,
    infinite: false,
    slidesToShow: items?.length,
    slidesToScroll: 2,
    arrows: false,
    focusOnSelect: true,
    centerPadding: '2px',
    asNavFor: mainSlider,
    ref: slider => (thumbnailsRef.current = slider),
  }

  useEffect(() => {
    setMainSlider(mainSliderRef.current)
    setThumbnails(thumbnailsRef.current)
  }, [])

  return (
    <section id={id} className="our-team">
      <Container className="our-team__main-container">
        <Row>
          <Col xs={12}>
            <RenderBlock
              className="our-team__title mt-4 mt-lg-5 text-center"
              data={title}
            />
          </Col>
        </Row>
        <Row className="justify-content-center pb-5 pb-lg-0">
          <Container className="p-0 p-md-3">
            <Slider {...settingsDesktop} className="slide-ourteam-person">
              {items?.map(item => (
                <div
                  key={item?._key}
                  className="position-relative pt-2 h-100 d-flex justify-content-center align-items-center flex-column"
                >
                  <Image
                    data={item?.image}
                    className="main-slider__image mt-lg-4 mb-4"
                  />
                  <Row className="bg-white w-100 main-slider__container-text">
                    <Col
                      xs={12}
                      className="mt-4 mt-md-0 justify-content-end align-items-center mx-lg-0 pt-3 main-slider__header"
                    >
                      <article className="mx-lg-3 my-lg-3 px-lg-2 ">
                        <RenderBlock
                          data={item?._rawTitle}
                          className="main-slider__title mt-3 mb-0"
                        />
                        <RenderBlock
                          data={item?._rawSubtitle}
                          className="main-slider__subtitle"
                        />
                        {item?._rawDescription && (
                          <hr className="main-slider__separator mb-4 mx-0" />
                        )}
                      </article>
                    </Col>
                  </Row>
                  <Row className="bg-white h-75 mt-0 mx-0 mt-lg-0 mb-4 mt-md-0 justify-content-end align-items-center mx-lg-0">
                    <Col xs={12} className="pb-5 pb-lg-0">
                      <article className=" main-slider__text-content mx-lg-3 mb-lg-5 mt-lg-0 p-lg-2">
                        <RenderBlock
                          data={item?._rawDescription}
                          className="main-slider__description"
                        />
                      </article>
                    </Col>
                  </Row>
                </div>
              ))}
            </Slider>
          </Container>
        </Row>
        <Row className="d-none d-lg-block pb-4 px-5 mx-5">
          <Col lg={12} className="justify-content-center px-5">
            <Slider {...settingsThumb} className="h-100 main-slider__selected">
              {items?.map(item => (
                <div className="w-100 h-100" key={item?._key}>
                  <Image
                    data={item?.image}
                    className="our-team-slide__thumbnail h-100 w-75"
                  />
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

OurTeam.propTypes = {
  title: PropTypes.shape({}).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
}

export default OurTeam
