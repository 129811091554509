import React from 'react'

import { Col, Container, Row } from 'reactstrap'
import ItemNews from './item-news'

const News = () => {
  return (
    <section className="news">
      <Container>
        <Row>
          <Col className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center">
            <h2 className="news__title text-center text-lg-left mt-4 mt-lg-5">
              Actualidad
            </h2>
            <a className="news__link mt-3 mt-lg-5 d-none" href="/#">
              Ver todo
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <ItemNews />
            <hr className="my-5" />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default News
