import React from 'react'

import { Row, Col } from 'reactstrap'

const ItemNews = () => {
  return (
    <article className="item-news">
      <Row className="flex-column flex-lg-row">
        <Col>
          <img
            alt="news"
            className="item-news__image mt-3"
            src="/Section_8A1.png"
          />
          <h5 className="item-news__title mt-4">
            Listas vinculantes SAGRILAFT. ¿Cuáles són?
          </h5>
          <h5 className="item-news__description mt-3 mb-4 mb-lg-3">
            Las listas vinculantes son una guía emitida por el Consejo de
            Seguridad de las Naciones Unidas.
          </h5>
          <a className="item-news__link d-none" href="/#">
            Leer más
          </a>
        </Col>
        <Col>
          <img alt="news" className="item-news__image mt-3" src="/Logo.png" />
          <h5 className="item-news__title mt-4">
            Todo lo que debes saber del nuevo SAGRILAFT.
          </h5>
          <h5 className="item-news__description mt-3 mb-4 mb-lg-3">
            SAGRILAFT busca proteger a las empresas del lavado de activos y la
            financiación del terrorismo.
          </h5>
          <a className="item-news__link d-none" href="/#">
            Leer más
          </a>
        </Col>
        <Col>
          <img
            alt="news"
            className="item-news__image mt-3"
            src="/Section_8A3.png"
          />
          <h5 className="item-news__title mt-4">
            ¿Cómo detectar posibles casos de fraude?
          </h5>
          <h5 className="item-news__description mt-3 mb-4 mb-lg-3">
            Estos son los principios clave para que las empresas puedan
            gestionar el riesgo de fraude.
          </h5>
          <a className="item-news__link d-none" href="/#">
            Leer más
          </a>
        </Col>
      </Row>
    </article>
  )
}

export default ItemNews
