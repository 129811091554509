import React from 'react'
import PropTypes from 'prop-types'

import { Container, Row, Col } from 'reactstrap'

import Image from 'components/ui/image'
import RenderBlock from 'components/ui/block-text'
import Anchor from 'components/ui/anchor-link'

const Risk = ({ title, description, background, cta }) => {
  return (
    <section className="risk">
      <Image data={background} className="risk__bg-image" />
      <Container className="risk__main-container py-lg-5">
        <Row className="">
          <Col xs={12} lg={5}>
            <RenderBlock className="risk__title mt-5" data={title} />
            <hr className="risk__separator mx-0" />
            <RenderBlock className="risk__description" data={description} />
            <Anchor
              className="risk__btn-main btn btn-lg btn-block my-5 my-lg-5"
              text={cta?.text}
              link={cta?.link}
              type={cta?.type}
              target={cta?.target}
              enable={cta?.enable}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

Risk.propTypes = {
  title: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  background: PropTypes.shape({}).isRequired,
  cta: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    target: PropTypes.string,
    enable: PropTypes.bool,
  }).isRequired,
}

export default Risk
