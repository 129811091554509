import React from 'react'
import PropTypes from 'prop-types'

import RenderBlock from 'components/ui/block-text'
import Image from 'components/ui/image'

const ItemBenefits = ({ description, image }) => {
  return (
    <div className="benefits-items d-flex align-items-start my-4 mt-lg-3">
      <Image className="benefits-items__icon mt-2 pr-4" data={image} />
      <RenderBlock
        className="benefits-items__description m-0"
        data={description}
      />
    </div>
  )
}

ItemBenefits.propTypes = {
  description: PropTypes.shape({}).isRequired,
  image: PropTypes.shape({}).isRequired,
}

export default ItemBenefits
