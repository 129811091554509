import React from 'react'

import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Penalties from 'components/home/penalties'
import HowItWork from 'components/home/how-it-work'
import OurServices from 'components/home/our-services'
import OurTeam from 'components/home/our-team'
import Risk from 'components/home/risk'
import Benefits from 'components/home/benefits'
import Calculate from 'components/home/calculate'
import Faq from 'components/home/faq'
import News from 'components/home/news'
import Contact from 'components/home/contact'

import Hero from '../components/home/hero'

const IndexPage = ({ data }) => {
  const {
    sanityPage: {
      tabs: {
        home,
        penalties,
        howItWork,
        ourServices,
        ourTeam,
        risk,
        benefits,
        calculate,
        faq,
        contact,
      },
    },
  } = data

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
    >
      <Layout>
        <SEO />
        {home?.enable && (
          <Hero
            banner={home?._rawHeroBanner}
            title={home?._rawTitle}
            description={home?._rawDescription}
            background={home?.background}
            ctaDataMain={home?.cta}
            ctaDataSecondary={home?.ctaTwo}
          />
        )}
        {penalties?.enable && (
          <Penalties
            title={penalties?._rawTitle}
            subtitle={penalties?._rawSubtitle}
            description={penalties?._rawDescription}
            background={penalties?.background}
            cta={penalties?.cta}
          />
        )}
        {howItWork?.enable && (
          <HowItWork
            title={howItWork?._rawTitle}
            subtitle={howItWork?._rawSubtitle}
            description={howItWork?._rawDescription}
            items={howItWork?.items}
            id="how-it-work"
          />
        )}
        {ourServices?.enable && (
          <OurServices
            title={ourServices?._rawTitle}
            description={ourServices?._rawDescription}
            items={ourServices?.items}
            id="services"
          />
        )}
        {ourTeam?.enable && (
          <OurTeam
            title={ourTeam?._rawTitle}
            items={ourTeam?.items}
            id="our-team"
          />
        )}
        {risk?.enable && (
          <Risk
            title={risk?._rawTitle}
            description={risk?._rawDescription}
            background={risk?.background}
            cta={risk?.cta}
          />
        )}
        {benefits?.enable && (
          <Benefits
            title={benefits?._rawTitle}
            background={benefits?.background}
            description={benefits?._rawDescription}
            items={benefits?.items}
          />
        )}
        {calculate?.enable && (
          <Calculate
            title={calculate?._rawTitle}
            subtitle={calculate?._rawSubtitle}
            description={calculate?._rawDescription}
            background={calculate?.background}
            cta={calculate?.cta}
            id="calculate"
          />
        )}
        {faq?.enable && <Faq title={faq?._rawTitle} items={faq?.items} />}
        <News />
        {contact?.enable && (
          <Contact
            id="contact"
            title={contact?._rawTitle}
            description={contact?._rawDescription}
            link={contact?._rawContactLink}
          />
        )}
      </Layout>
    </GoogleReCaptchaProvider>
  )
}

export default IndexPage

IndexPage.defaultProps = {
  data: null,
}

IndexPage.propTypes = {
  data: PropTypes.shape(PropTypes.object),
}

export const IndexQuery = graphql`
  query MyQuery {
    sanityPage(slug: { current: { eq: "home" } }) {
      ...IndexParts
    }
  }
`
