import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMediaQueries } from '@react-hook/media-query'

import { Col, Container, Row } from 'reactstrap'

import RenderBlock from 'components/ui/block-text'
import ItemFaq from './item'

const Faq = ({ title, items }) => {
  const mapBlock = items.map(() => false)
  const [isOpenBlock, setIsOpenBlock] = useState(mapBlock)

  const {
    matches: { mobile },
  } = useMediaQueries({
    mobile: 'only screen and (max-width: 580px)',
  })

  const handleToggleCollapse = index => () => {
    const opens = [...isOpenBlock]
    opens[index] = !opens[index]
    if (!mobile) {
      const numberColumn = index + 1
      if (numberColumn % 2 === 0) {
        opens[index - 1] = !opens[index - 1]
      } else {
        opens[index + 1] = !opens[index + 1]
      }
    }
    setIsOpenBlock(opens)
  }

  return (
    <section className="faq py-lg-4">
      <Container className="faq__main-container mt-0 mb-3 py-3 my-md-5 pt-md-5 pb-md-5">
        <Row className="">
          <Col>
            <RenderBlock
              className="faq__title text-center mt-5 mt-lg-2"
              data={title}
            />
          </Col>
        </Row>
        <Row className="mt-3 mb-5 mb-lg-0">
          {items?.map((item, index) => (
            <Col xs={12} md={6} className="" key={item?._key}>
              <ItemFaq
                title={item?._rawTitle}
                description={item?._rawDescription}
                isOpen={isOpenBlock[index]}
                toggle={handleToggleCollapse}
                id={index}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

Faq.propTypes = {
  title: PropTypes.shape({}).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default Faq
