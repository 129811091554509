import React from 'react'
import PropTypes from 'prop-types'

import { Row, Col, Container } from 'reactstrap'

import RenderBlock from 'components/ui/block-text'

import Slider from 'react-slick'
import ItemSlider from './item-slider'
import Tabs from './tabs'

const OurServices = ({ title, description, items, id }) => {
  const settings = {
    dots: true,
    fade: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
  }

  return (
    <section id={id} className="our-services mt-5 d-block pb-4">
      <Container className="our-services__main-container text-center">
        <Row className="our-services__main-section pb-5">
          <Col xs={12} className="mt-5">
            <RenderBlock className="our-services__title mt-lg-4" data={title} />
            <hr className="our-services__separator" />
            <RenderBlock
              className="our-services__description mb-0 mb-lg-5"
              data={description}
            />
          </Col>
        </Row>
        <Row className="our-services__secondary-section-mobile d-block d-md-none">
          <Col xs={12} className="mx-0 px-0">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Slider {...settings}>
              {items?.map(item => (
                <article key={item?._key}>
                  <ItemSlider
                    key={item?._key}
                    title={item?._rawTitle}
                    subtitle={item?._rawSubtitle}
                    description={item?._rawDescription}
                    icon={item?.icon}
                  />
                </article>
              ))}
            </Slider>
          </Col>
        </Row>
        <Row className="our-services__secondary-section-desktop d-none d-lg-block">
          <Col lg={12}>
            <Tabs items={items} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

OurServices.propTypes = {
  title: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
}

export default OurServices
