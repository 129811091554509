import React from 'react'
import PropTypes from 'prop-types'

import RenderBlock from 'components/ui/block-text'
import Image from 'components/ui/image'

const ItemHowItWork = ({ title, description, image }) => {
  return (
    <div className="item-content">
      <Image className="item-content__image mt-5" data={image} />
      <RenderBlock className="item-content__title mt-3 mb-2" data={title} />
      <RenderBlock className="item-content__description" data={description} />
    </div>
  )
}

ItemHowItWork.propTypes = {
  title: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  image: PropTypes.shape({}).isRequired,
}

export default ItemHowItWork
