import React from 'react'
import PropTypes from 'prop-types'

import { Collapse, Col, Row } from 'reactstrap'

import RenderBlock from 'components/ui/block-text'
import PlusIcon from 'components/icons/PlusIcon'

const ItemFaq = ({ title, description, toggle, isOpen, id }) => {
  return (
    <Row className="faq-item mx-3 mt-4 p-2">
      <Col xs={12}>
        <Row
          onClick={toggle(id)}
          className="justify-content-center align-items-center"
        >
          <Col
            xs={12}
            className="d-flex justify-content-between align-items-center"
          >
            <RenderBlock
              className="faq-item__title text-left py-2 pr-4 m-0"
              data={title}
            />
            <div
              className={`faq-item${
                isOpen ? '__close' : '__open'
              } p-2  rounded-circle d-flex justify-content-center align-items-center`}
            >
              <PlusIcon cssClass="faq-item__icon" />
            </div>
          </Col>
        </Row>
        <Collapse isOpen={isOpen} className="pt-2">
          <hr />
          <Row className="px-0 pt-0 pb-3 align-items-center">
            <Col xs={12} md={12} className="pr-4">
              <article className="">
                <RenderBlock
                  className="description text-left m-0"
                  data={description}
                />
              </article>
            </Col>
          </Row>
        </Collapse>
      </Col>
    </Row>
  )
}

ItemFaq.propTypes = {
  title: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
}

export default ItemFaq
