import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap'
import classnames from 'classnames'

import Image from 'components/ui/image'
import RenderBlock from 'components/ui/block-text'

const Tabs = ({ items }) => {
  const [activeTab, setActiveTab] = useState(0)

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <div className="tabs">
      <Nav tabs className="">
        {items?.map((item, index) => (
          <NavItem className="tabs__item" key={item?._key}>
            <NavLink
              className={classnames({ active: activeTab === index })}
              onClick={() => {
                toggle(index)
              }}
            >
              <Image className="tabs__menu-icon" data={item?.icon} />
              <RenderBlock
                className="tabs__menu-title"
                data={item?._rawTitle}
              />
              <hr className="tabs__separator-menu" />
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {items?.map((item, index) => (
          <TabPane tabId={index} key={item?._key}>
            <Row className="tabs__items-container pb-lg-5">
              <Col lg={6}>
                <RenderBlock
                  className="tabs__content-subtitle mt-5 w-50 text-left"
                  data={item?._rawSubtitle}
                />
                <hr className="tabs__separator-text my-2 my-lg-3 mx-0" />
                <RenderBlock
                  className="tabs__content-description text-left w-75"
                  data={item?._rawDescription}
                />
              </Col>
              <Col
                lg={6}
                className="d-flex justify-content-center align-items-start mt-lg-5"
              >
                <Image
                  className="tabs__content-image mt-lg-5"
                  data={item?.image}
                />
              </Col>
            </Row>
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default Tabs
